<script>
import { api } from "@/state/services";
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  components: {
    VueSvgGauge
  },
  data() {
    return {
      total: 0,
      points: 0,
      graduations: null,
      previous: {
        id: null,
        name: '',
        points: 0,
        image: null,
      },
      next: {
        id: null,
        name: '',
        points: 0,
        image: null
      },
      progress: 0
    };
  },
  methods: {
    getGraduation() {
      api
        .get('reports/graduation')
        .then(response => {
          if (response.data.status == 'success') {
            this.graduations = response.data.list
            this.total = response.data.graduation.total
            this.points = response.data.graduation.points
            this.previous = response.data.graduation.previous
            this.next = response.data.graduation.next
            this.progress = this.points - this.previous.points
          }
        })
    }
  },
  created() {
    this.getGraduation();
  }
};
</script>

<template>
  <div>
    <div v-if="points && previous && previous.points && next && next.points" style="max-width:350px;" class="m-auto">
      <vue-svg-gauge class="px-3" :start-angle="-110" :end-angle="110" :value="points - previous.points" :separator-step="0" :min="Number(0)" :max="Number(next.points - previous.points)"
        gauge-color="#000001" :scale-interval="0" />
    </div>
    <div v-if="points && previous && previous.points && next && next.points" class="report text-center">
      <h2 class="notranslate">{{ points | points }}</h2>
      <p class="text-uppercase font-size-14 label">Pontos<br>Aproveitados</p>
      <div class="d-flex justify-content-around mt-4">
        <div>
          <span class="text-uppercase font-size-12">Atual</span>
          <div class="d-block">
            <img v-if="previous.image" :src="require('@/assets/images/graduations/' + previous.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0 notranslate">{{ previous.points | points }}</h3>
          <p class="m-0 notranslate">{{ previous.name }} </p>
        </div>
        <div class="pt-4 mt-4">
          <h4 class="notranslate pt-1">{{ total | points }}</h4>
          <p class="text-uppercase font-size-14 label">Pontos<br>Totais</p>
        </div>
        <div>
          <span class="text-uppercase font-size-12">Próximo</span>
          <div class="d-block">
            <img v-if="next.image" :src="require('@/assets/images/graduations/' + next.image)" class="rounded-circle bg-white" width="80" />
            <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle bg-white" width="80" />
          </div>
          <h3 class="m-0 notranslate">{{ next.points | points }}</h3>
          <p class="m-0 notranslate">{{ next.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report {
  margin-top: -90px;
}

.label {
  line-height: 13px;
}
</style>